import React from "react";
import Layout from "components/layout";
import { GlobalState, UserState, SearchState } from "utils/types";
import { compose } from "redux";
import { connect } from "react-redux";
import { Typography, Theme, makeStyles, Button } from "@material-ui/core";
import { seedFullTextSearchIndex } from "redux/actions/assignment";
import { setAlgoliaAssignmentSearch } from "redux/actions/search"

interface Props {
  userState: UserState
  searchState: SearchState
  seedFullTextSearchIndex: () => void
  setAlgoliaAssignmentSearch: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  topBar: {
    minHeight: "110px",
    marginBottom: theme.spacing(2),
    background: "#ffffff",
    padding: theme.spacing(3, 8)
  }
}));


function Profile(props: Props) {
  const env = process.env.REACT_APP_RELEASE_STAGE
  const classes = useStyles()
  // isolate role from permissions
  const roleString = props.userState.me.permissions.find(role => role.includes("role"))
  // set display string for user role
  let roleDisplay
  switch (roleString) {
    case "admin-app:role-adjuster":
      roleDisplay = "Adjuster"
      break
    case "admin-app:role-supervisor":
      roleDisplay = "Supervisor"
      break
    case "admin-app:role-admin":
      roleDisplay = "Admin"
      break
    case "admin-app:role-support":
      roleDisplay = "Support"
      break
    case undefined:
      roleDisplay = "No Assigned Role"
      break
  }
  return <Layout page="profile">
    <div className={classes.topBar}>
    <Typography variant="h4">
    {props.userState.me.displayName}
      </Typography>
      <Typography variant="h5">
        {props.userState.me.email}
      </Typography>
      <Typography variant="h5">
        {roleDisplay}
      </Typography>
      {env === "local" ? <Button style={{ backgroundColor: "green", color: "white" }} onClick={() => {
        props.setAlgoliaAssignmentSearch()
        props.seedFullTextSearchIndex()}}>Seed Full Text Search Index</Button> : ""}
    </div>
  </Layout>;
}

const mapStateToProps = (state: GlobalState) => ({
  userState: state.user,
  searchState: state.search
})

const mapDispatchToProps = {
  seedFullTextSearchIndex,
  setAlgoliaAssignmentSearch
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Profile)