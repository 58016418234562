import algoliaSearch from "algoliasearch";
import firebase from "firebase";
import { FullTextSearchAssignmentRepo } from "domain/repos/AssignmentRepo/FullTextSearchAssignmentRepo";
import { minmax } from "../../domain/minmax";
import * as AssignmentList from "domain/repos/AssignmentRepo/AssignmentList";

const algoliaAppId = process.env.REACT_APP_ALGOLIA_APP_ID;
const algoliaSearchKey = process.env.REACT_APP_ALGOLIA_SEARCH_KEY;
const algoliaAssignmentIndex = process.env.REACT_APP_ALGOLIA_ASSIGNMENT_INDEX;

const algoliaClient = algoliaSearch(algoliaAppId, algoliaSearchKey);

// temp local index
const algoliaIndex = algoliaClient.initIndex(algoliaAssignmentIndex);

export class AlgoliaAssignmentRepo implements FullTextSearchAssignmentRepo {
  constructor(private db: firebase.firestore.Firestore) {}

  // Algolia Query Attribute docs https://www.algolia.com/doc/api-reference/api-parameters/searchableAttributes/

  async algoliaFetchAssignments(request: AssignmentList.Request) {
    const curr = {
      ...request,
      page: request.page || 1,
      limit: minmax(1, 20, request.limit)
    };
    // establish default query. Always search with page and limit
    let queryAttributes = {};
    const facetFilters = [];

    queryAttributes = {
      page: curr.page - 1,
      hitsPerPage: curr.limit
    };

    // role based filters

    if (curr.role === "admin-app:role-adjuster") {
      queryAttributes = {
        ...queryAttributes,
        filters: `usersWithTasksAssigned: ${curr.withUserId}`
      };
    }

    if (curr.role === "admin-app:role-supervisor") {
      // fetch supervisor's user doc
      const supervisor = await (
        await this.db.collection("users").doc(curr.withUserId).get()
      ).data();
      const supervisorFor = supervisor.supervisorFor;
      const supervisorIdFilter = [];
      supervisorFor.map((clientId) => {
        return facetFilters.push(`clientId: ${clientId}`);
      });
      facetFilters.push(supervisorIdFilter);
    }

    // client Id

    if (request.withClientIdEq) {
      facetFilters.push(`clientId: ${request.withClientIdEq}`);
    }

    // assignment status

    if (request.withAssignmentStatusEq) {
      facetFilters.push(`assignmentStatus: ${request.withAssignmentStatusEq}`);
    }

    // supervisor name

    if (request.withSupervisorIdEq) {
      facetFilters.push(`supervisorId: ${request.withSupervisorIdEq}`);
    }

    // assignment type

    if (request.withAssignmentBlueprintIdEq) {
      facetFilters.push(`assignmentBlueprintId: ${request.withAssignmentBlueprintIdEq}`);
    }

    // set any custom ranking setting

    if (request.orderBy) {
      if (request.orderBy === "+receivedAt") {
        algoliaIndex.setSettings({
          ranking: ["asc(receivedAt)"]
        });
      }
      if (request.orderBy === "-receivedAt") {
        algoliaIndex.setSettings({
          ranking: ["desc(receivedAt)"]
        });
      }
    }

    // run the query

    queryAttributes = { ...queryAttributes, facetFilters };

    const assignments = (await algoliaIndex.search(
      request.withExternalFriendlyIdPrefix,
      queryAttributes
    )) as any;

    // returns the next request and previous request so they can be passed in the new request when a page arrow is clicked
    const next = assignments.nbPages === request.page ? null : { ...curr, page: curr.page + 1 };
    const prev = curr.page === 1 ? null : { ...curr, page: curr.page - 1 };

    return {
      assignments: assignments.hits,
      pages: {
        curr,
        next,
        prev,
        number: assignments.nbPages
      }
    };
  }
}
