import firebase from "firebase";

import * as AssignmentList from "domain/repos/AssignmentRepo/AssignmentList";
import * as AssignmentDetail from "domain/repos/AssignmentRepo/AssignmentWithTask";
import AssignmentRepo from "domain/repos/AssignmentRepo";
import { Assignment, Client, User, Prop, SubtaskEnvelope } from "@fpd-cloud/schemas/core";
import {
  assignmentConverter,
  clientConverter,
  userConverter,
  taskConverter,
  subTaskConverter
} from "../converters";
import { minmax } from "../../../domain/minmax";
import { ExntendedTaskEnvelope } from "utils/types";

const orderByFor = (request: AssignmentList.Request): AssignmentList.OrderBy => {
  if (request.withExternalFriendlyIdPrefix?.length) {
    return "+externalFriendlyId";
  }
  if (request.withInternalFriendlyIdPrefix?.length) {
    return "+internalFriendlyId";
  }

  return request.orderBy || "-receivedAt";
};

// Required reading: https://firebase.google.com/docs/firestore/query-data/queries#query_limitations
export class FirestoreAssignmentRepo implements AssignmentRepo {
  constructor(private db: firebase.firestore.Firestore) {}

  private async fetchAssignmentData(request: AssignmentList.Request) {
    // 1. "normalize" the request to ensure page, limit and orderBy have a value:
    // -----------------------------------------------------------
    const curr = {
      ...request,
      page: request.page || 1,
      limit: minmax(1, 20, request.limit),
      orderBy: orderByFor(request)
    };

    // 2. build a query, based on the normalized request aka: curr
    // -----------------------------------------------------------
    let query = curr.endBefore
      ? this.db.collection("assignments").limitToLast(curr.limit)
      : this.db.collection("assignments").limit(curr.limit);

    // set orderBy first
    if (curr.orderBy === "+createdAt") query = query.orderBy("createdAt", "asc");
    if (curr.orderBy === "-createdAt") query = query.orderBy("createdAt", "desc");
    if (curr.orderBy === "+externalFriendlyId") query = query.orderBy("externalFriendlyId", "asc");
    if (curr.orderBy === "-externalFriendlyId") query = query.orderBy("extrnalFriendlyId", "desc");
    if (curr.orderBy === "+internalFriendlyId") query = query.orderBy("internalFriendlyId", "asc");
    if (curr.orderBy === "-internalFriendlyId") query = query.orderBy("internalFriendlyId", "desc");
    if (curr.orderBy === "+receivedAt") query = query.orderBy("receivedAt", "asc");
    if (curr.orderBy === "-receivedAt") query = query.orderBy("receivedAt", "desc");

    // then tell it where we want to start
    if (curr.startAfter) query = query.startAfter(curr.startAfter);
    if (curr.endBefore) query = query.endBefore(curr.endBefore);

    // Equality filters ('==')
    // ------------------
    if (curr.withClientIdEq?.length > 0) {
      query = query.where("clientId", "==", curr.withClientIdEq);
    }

    if (curr.withSupervisorIdEq?.length > 0) {
      query = query.where("supervisorId", "==", curr.withSupervisorIdEq);
    }

    if (curr.withAssignmentBlueprintIdEq?.length > 0) {
      query = query.where("assignmentBlueprintId", "==", curr.withAssignmentBlueprintIdEq);
    }

    if (curr.withAssignmentStatusEq?.length > 0) {
      query = query.where("assignmentStatus", "==", curr.withAssignmentStatusEq);
    }

    // Logical OR filters ('in' and 'array-contains-any')
    // ------------------
    const withClientIdIn = toSet(curr.withClientIdIn);
    if (withClientIdIn.size > 0) {
      query = query.where("clientId", "in", Array.from(withClientIdIn));
    }

    const withSupervisorIdIn = toSet(curr.withSupervisorIdIn);
    if (withSupervisorIdIn.size > 0) {
      query = query.where("supervisorId", "in", Array.from(withSupervisorIdIn));
    }

    const withAssignmentBlueprintIdIn = toSet(curr.withAssignmentBlueprintIdIn);
    if (withAssignmentBlueprintIdIn.size > 0) {
      query = query.where("assignmentBlueprintId", "in", Array.from(withAssignmentBlueprintIdIn));
    }

    const withAssignmentStatusIn = toSet(curr.withAssignmentStatusIn);
    if (withAssignmentStatusIn.size > 0) {
      query = query.where("assignmentStatus", "in", Array.from(withAssignmentStatusIn));
    }

    const withTaskStatusIn = toSet(curr.withTaskStatusIn);
    if (withTaskStatusIn.size > 0) {
      query = query.where("taskStatuses", "array-contains-any", Array.from(withTaskStatusIn));
    }

    // Range filters:
    // ------------------
    if (curr.withExternalFriendlyIdPrefix?.length > 0) {
      query = query
        .where("externalFriendlyId", ">=", curr.withExternalFriendlyIdPrefix)
        .where("externalFriendlyId", "<=", curr.withExternalFriendlyIdPrefix + "\uf8ff");
    }

    if (curr.withInternalFriendlyIdPrefix?.length > 0) {
      query = query
        .where("internalFriendlyId", ">=", curr.withInternalFriendlyIdPrefix)
        .where("internalFriendlyId", "<=", curr.withInternalFriendlyIdPrefix + "\uf8ff");
    }

    // Role Based Filters:
    // --------------------------

    if (curr.role === "admin-app:role-adjuster") {
      query = query.where("usersWithTasksAssigned", "array-contains", curr.withUserId);
    }

    if (curr.role === "admin-app:role-supervisor") {
      // fetch supervisor's user doc
      const supervisor = await (
        await this.db.collection("users").doc(curr.withUserId).get()
      ).data();
      const supervisorFor = supervisor.supervisorFor;
      query = query.where("clientId", "in", supervisorFor);
    }

    // 3. run the query / start fetching data
    // -----------------------------------------------------------

    // fetch assignments

    const snapshots = await query.withConverter(assignmentConverter).get();
    if (snapshots.empty) return [];

    const assignments = snapshots.docs.map((doc) => doc.data());
    return assignments;
  }

  async fetchAssignments(request: AssignmentList.Request): Promise<AssignmentList.Response | null> {
    if (!request) return null;
    const curr = {
      ...request,
      page: request.page || 1,
      limit: minmax(1, 20, request.limit),
      orderBy: orderByFor(request)
    };
    const assignments = await this.fetchAssignmentData(request);
    // fetch clients
    // const clients = await this.fetchClients(assignments);
    // const clientsById = clients.reduce((memo, client) => {
    //   memo[client.id] = client;
    //   return memo;
    // }, {});

    // fetch supervisors
    // const supervisors = await this.fetchSupervisors(assignments);
    // const supervisorsById = supervisors.reduce((memo, user) => {
    //   memo[user.id] = user;
    //   return memo;
    // }, {});

    // 4. return our results
    // ---------------------
    const next = await this.buildNext(curr, assignments);
    return {
      assignments,
      // clientsById,
      // supervisorsById,
      pages: {
        curr,
        next,
        prev: this.buildPrev(curr, assignments)
      }
    };
  }

  async fetchAssignment(
    request: AssignmentDetail.Request
  ): Promise<AssignmentDetail.Response | null> {
    const query = this.db.collection("assignments").doc(request.id);

    const assignmentSnapshot = await query.withConverter(assignmentConverter).get();
    if (!assignmentSnapshot.exists) return null;
    const assignment = assignmentSnapshot.data();
    const tasks = await this.fetchTasks(assignment.id);
    const props = await this.fetchProps(assignment.id);
    return {
      assignmentEnvelope: {
        assignment,
        assignmentProps: props,
        taskEnvelopes: tasks
      }
    };
  }

  async fetchSubTasks(taskId) {
    const snapshots = await this.db
      .collection("subtasks")
      .limit(100)
      .where("taskId", "==", taskId)
      .orderBy("position")
      .withConverter(subTaskConverter)
      .get();
    if (snapshots.empty) {
      return null;
    }
    const subtasks: SubtaskEnvelope[] = [];
    await Promise.all(
      snapshots.docs.map(async (doc) => {
        const subtask = doc.data();
        const subtaskProps = await this.fetchSubTaskProps(subtask.id);
        subtasks.push({
          subtask,
          subtaskProps
        });
      })
    );
    return subtasks;
  }

  private async fetchClients(assignments: Assignment[]): Promise<Client[]> {
    const ids = toSet(assignments.map((assignment) => assignment.clientId));
    if (ids.size === 0) {
      return [];
    }

    // TODO handle cases where ids.size > 10

    const snaps = await this.db
      .collection("clients")
      .limit(100)
      .withConverter(clientConverter)
      .where("id", "in", Array.from(ids))
      .get();

    return snaps.docs.map((doc) => doc.data());
  }

  private async fetchSupervisors(assignments: Assignment[]): Promise<User[]> {
    const ids = toSet(assignments.filter((a) => a.supervisorId).map((a) => a.supervisorId));
    if (ids.size === 0) {
      return [];
    }

    // TODO handle cases where ids.size > 10

    const snaps = await this.db
      .collection("users")
      .limit(100)
      .withConverter(userConverter)
      .where("id", "in", Array.from(ids))
      .get();

    return snaps.docs.map((doc) => {
      return doc.data();
    });
  }

  private async fetchTasks(assignmentId: string) {
    let query = this.db.collection("tasks").orderBy("position").limit(100);
    query = query.where("assignmentId", "==", assignmentId);
    const taskSnapshots = await query.withConverter(taskConverter).get();
    const tasks: ExntendedTaskEnvelope[] = [];
    await Promise.all(
      taskSnapshots.docs.map(async (doc) => {
        const taskData = doc.data();
        const subTasks = await this.fetchSubTasks(taskData.id);
        const props = await this.fetchTaskProps(taskData.id);
        const client = await this.fetchClient(taskData.clientId);
        tasks.push({
          task: taskData,
          taskProps: props,
          subtaskEnvelopes: subTasks,
          client
        });
      })
    );
    return tasks;
  }

  private async fetchProps(assignmentId: string) {
    const query = this.db
      .collection("assignments")
      .doc(assignmentId)
      .collection("props")
      .orderBy("position")
      .limit(100);
    const propSnapshots = await query.get();
    return propSnapshots.docs.map((doc) => {
      return doc.data() as Prop;
    });
  }

  private async fetchTaskProps(taskId: string) {
    const query = this.db
      .collection("tasks")
      .doc(taskId)
      .collection("props")
      .orderBy("position")
      .limit(100);
    const propSnapshots = await query.get();
    return propSnapshots.docs.map((doc) => {
      return doc.data() as Prop;
    });
  }

  private async fetchSubTaskProps(subtaskId: string) {
    const query = this.db
      .collection("subtasks")
      .doc(subtaskId)
      .collection("props")
      .orderBy("position")
      .limit(100);
    const propSnapshots = await query.get();
    return propSnapshots.docs.map((doc) => {
      return doc.data() as Prop;
    });
  }

  private async fetchClient(clientId: string) {
    const query = this.db.collection("clients").doc(clientId);

    const doc = await query.get();
    return doc.data() as Client;
  }

  private async buildNext(
    curr: AssignmentList.Request,
    assignments: Assignment[]
  ): Promise<AssignmentList.Request | null> {
    if (assignments.length === 0 || assignments.length < curr.limit) {
      return null;
    }

    const lastAssignment = assignments[assignments.length - 1];
    let startAfter: string | Date;
    if (curr.orderBy === "+createdAt") startAfter = lastAssignment.createdAt;
    if (curr.orderBy === "-createdAt") startAfter = lastAssignment.createdAt;
    if (curr.orderBy === "+internalFriendlyId") startAfter = lastAssignment.internalFriendlyId;
    if (curr.orderBy === "-internalFriendlyId") startAfter = lastAssignment.internalFriendlyId;
    if (curr.orderBy === "+receivedAt") startAfter = lastAssignment.receivedAt;
    if (curr.orderBy === "-receivedAt") startAfter = lastAssignment.receivedAt;

    const nextRequest = {
      ...curr,
      page: curr.page + 1,
      startAfter,
      endBefore: null
    };
    const nextAssignments = await this.fetchAssignmentData(nextRequest);
    if (nextAssignments.length === 0) return null;
    return nextRequest;
  }

  private buildPrev(
    curr: AssignmentList.Request,
    assignments: Assignment[]
  ): AssignmentList.Request | null {
    if (assignments.length === 0 || curr.page === 1) {
      return null;
    }

    const firstAssignment = assignments[0];
    // eslint-disable-next-line
    let endBefore: string | Date;
    if (curr.orderBy === "+createdAt") endBefore = firstAssignment.createdAt;
    if (curr.orderBy === "-createdAt") endBefore = firstAssignment.createdAt;
    if (curr.orderBy === "+internalFriendlyId") endBefore = firstAssignment.internalFriendlyId;
    if (curr.orderBy === "-internalFriendlyId") endBefore = firstAssignment.internalFriendlyId;
    if (curr.orderBy === "+receivedAt") endBefore = firstAssignment.receivedAt;
    if (curr.orderBy === "-receivedAt") endBefore = firstAssignment.receivedAt;

    return {
      ...curr,
      page: curr.page - 1,
      startAfter: null,
      endBefore
    };
  }
}

const toSet = (arr: string[]): Set<string> => new Set((arr || []).filter(present));

const present = (v: string) => v !== null && v !== "";
