import React, { useState, useEffect } from "react";
import moment from "moment"
import Layout from "components/layout";
import {
  CircularProgress,
  Grid,
  Theme,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  makeStyles
} from "@material-ui/core";
import { connect } from "react-redux";
import { compose } from "redux";

import { GlobalState, UserState, SearchState } from "utils/types";
import { readAllUsersFromRepo, searchAlgoliaUsers } from "redux/actions/user";

import * as UserList from "domain/repos/UserRepo/UserList";

import TablePaginationComponent from "../../../components/common/table-pagination";

const useStyles = makeStyles((theme: Theme) => ({
  topBar: {
    minHeight: "110px",
    marginBottom: theme.spacing(2),
    background: "#ffffff",
    padding: theme.spacing(3, 8)
  },
  tableWrapper: {
    backgroundColor: "#E5E5E5",
    padding: theme.spacing(2, 8, 4, 8)
  },
  searchInput: {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    height: "40px",
    backgroundImage: "url('../../../assets/images/search-icon.svg')",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "15px 12px",
    padding: "10px 40px",
    borderRadius: "4px",
    marginRight: "20px",
    minWidth: 295
  },
  tableContainer: {
    maxHeight: 600,
    "& th": {
      color: "#01788B",
      fontWeight: "bold"
    }
  }
}));

interface Props {
  userState: UserState;
  searchState: SearchState
  readAllUsersFromRepo: (request: UserList.Request) => void;
  searchAlgoliaUsers: (request: UserList.Request) => void
}

function UserListPage(props: Props) {
  const [request, setRequest] = useState<UserList.Request>({
    page: 1,
    limit: 10
  });

  const classes = useStyles();
  useEffect(() => {
    if (props.searchState.algoliaAssignments) {
      props.searchAlgoliaUsers(request)
    } else {
      props.readAllUsersFromRepo(request);
    }
    // eslint-disable-next-line
  }, [request]);

  const userList: any = props.userState ? props.userState.users : [];

  if (props.userState.error != null) {
    throw props.userState.error;
  }

  return (
    <Layout page="users">
      <div className={classes.topBar}>
        <Grid
          container
          justify='space-between'
          direction='row'
          alignItems='center'
          style={{ marginBottom: "20px" }}
        >
          <Grid>
            <Typography variant='h5'>Users</Typography>
          </Grid>
          <Grid item style={{ display: "flex",  flexDirection: "column"}}>
            <input
              placeholder='Search for Users by Name or Email'
              className={classes.searchInput}
              onChange={(evt) => {
              setRequest({
              ...request,
              searchString: `${evt.target.value as string}`
              });
              }}
              />
              {props.searchState.algoliaAssignments === true ?
            <img src='assets/images/search-by-algolia-light-background.webp' width='120px' alt=""/>
              : ""}
              </Grid>
        </Grid>
      </div>
      <div className={classes.tableWrapper}>
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>XactNet Address</TableCell>
                <TableCell>Last Logged In</TableCell>
              </TableRow>
            </TableHead>
            {!userList.users || props.userState.status === "INIT" ? (
              <TableBody style={{ backgroundColor: "white" }}>
                <TableRow>
                  <TableCell colSpan={8} style={{ textAlign: "center" }}>
                    <CircularProgress color='primary' />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : userList.users.length === 0 ? (
              <TableBody style={{ backgroundColor: "white" }}>
                <TableRow>
                  <TableCell colSpan={8} style={{ textAlign: "center" }}>
                    No users found
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody style={{ backgroundColor: "white" }}>
                {userList.users.map((user: any) => {
                  return (
                    <TableRow hover key={user.id} data-testid='user-row'>
                      <TableCell>{user.displayName}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.xactnetAddress}</TableCell>
                      <TableCell>
                        {user.lastSignInAt ? moment(user.lastSignInAt, 'x').format('ll') : "Has Not Logged In"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {props.userState && userList.users && userList.users.length > 0 && (
          <TablePaginationComponent response={userList} setRequest={setRequest} />
        )}
      </div>
    </Layout>
  );
}

const mapStateToProps = (state: GlobalState) => ({
  userState: state.user,
  searchState: state.search
});

const mapDispatchToProps = {
  readAllUsersFromRepo,
  searchAlgoliaUsers
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(UserListPage);
