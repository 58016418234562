import algoliaSearch from "algoliasearch";
import firebase from "firebase";
import { FullTextSearchUserRepo } from "domain/repos/UserRepo/FullTextSearchUserRepo";
import { minmax } from "../../domain/minmax";
import * as UserList from "domain/repos/UserRepo/UserList";

const algoliaAppId = process.env.REACT_APP_ALGOLIA_APP_ID;
const algoliaSearchKey = process.env.REACT_APP_ALGOLIA_SEARCH_KEY;
const algoliaUserIndex = process.env.REACT_APP_ALGOLIA_USER_INDEX;

const algoliaClient = algoliaSearch(algoliaAppId, algoliaSearchKey);

// temp local index
const algoliaIndex = algoliaClient.initIndex(algoliaUserIndex);

export class AlgoliaUserRepo implements FullTextSearchUserRepo {
  constructor(private db: firebase.firestore.Firestore) {}

  // Algolia Query Attribute docs https://www.algolia.com/doc/api-reference/api-parameters/searchableAttributes/

  async algoliaFetchUsers(request: UserList.Request) {
    const curr = {
      ...request,
      page: request.page || 1,
      limit: minmax(1, 100, request.limit)
    };

    let queryAttributes = {};

    queryAttributes = {
      page: curr.page - 1,
      hitsPerPage: curr.limit
    };
    // establish default query. Always search with page and limit

    const users = (await algoliaIndex.search(request.searchString, queryAttributes)) as any;

    // returns the next request and previous request so they can be passed in the new request when a page arrow is clicked
    const next = users.nbPages === request.page ? null : { ...curr, page: curr.page + 1 };
    const prev = curr.page === 1 ? null : { ...curr, page: curr.page - 1 };

    return {
      users: users.hits,
      pages: {
        curr,
        next,
        prev,
        number: users.nbPages
      }
    };
  }
}
