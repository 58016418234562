import { all } from "redux-saga/effects";

import userSaga from "./user";
import assignmentSaga from "./assignment";
import clientSaga from "./client";
import teamSaga from "./team";
import taskSaga from "./task";
import envelopeSaga from "./envelope";
import fieldProSaga from "./fieldPros";
import searchSaga from "./search";

export default function* root() {
  yield all([
    userSaga(),
    assignmentSaga(),
    clientSaga(),
    teamSaga(),
    taskSaga(),
    envelopeSaga(),
    fieldProSaga(),
    searchSaga()
  ]);
}
