import { put, takeLatest, select } from "redux-saga/effects";
import { AnyAction } from "redux";

import firebase, { db } from "utils/firebase";
import {
  READ_ASSIGNMENTS,
  SAVE_NEW_ASSIGNMENT,
  UPDATE_ASSIGNMENTS_STATUS,
  READ_ASSIGNMENT,
  UPDATE_TASK_STATUS,
  UPDATE_ASSIGNMENT_PROP,
  ASSIGN_TASK_TO_USER,
  READ_ALL_TASKS,
  READ_ASSINGABLE_SUPERVISORS,
  ASSIGN_SUPERVISOR,
  READ_SUBTASK_LIST,
  UPDATE_ASSIGNMENT_STATUS,
  readAllAssignments,
  readAssignment,
  SEED_FULL_TEXT_SEARCH_INDEX,
  SEARCH_ALGOLIA
} from "../actions/assignment";
import { apiSuccess, apiError } from "redux/makeRequest";
import { GlobalState } from "utils/types";
import Bugsnag from "@bugsnag/js";
import AssignmentRepo from "domain/repos/AssignmentRepo";
import { FirestoreAssignmentRepo } from "data-access/firestore/repos";
import apiCall from "redux/apiCall";
import { FullTextSearchAssignmentRepo } from "domain/repos/AssignmentRepo/FullTextSearchAssignmentRepo";
import { AlgoliaAssignmentRepo } from "data-access/algolia/AlgoliaAssignmentRepo";

let repo: AssignmentRepo;
repo = new FirestoreAssignmentRepo(db);

let fullTextSearchRepo: FullTextSearchAssignmentRepo;
fullTextSearchRepo = new AlgoliaAssignmentRepo(db);
const { serverTimestamp } = firebase.firestore.FieldValue;

// TODO(Daniel): Need to update AnyAction Type into correct payload object type.

function* doRepoGetAllAssignments(action: AnyAction) {
  try {
    const response = yield repo.fetchAssignments(action.payload || {});
    yield put({
      type: apiSuccess(action.type),
      payload: response
    });
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

function* doSearchAlgolia(action: AnyAction) {
  try {
    const response = yield fullTextSearchRepo.algoliaFetchAssignments(action.payload);
    yield put({
      type: apiSuccess(action.type),
      payload: response
    });
  } catch (error) {
    Bugsnag.notify(error);
  }
}

function* doRepoGetSubtasks(action: AnyAction) {
  try {
    // const response = yield repo.fetchSubTasks({ taskBlueprintIdEq: action.payload });
    yield put({
      type: apiSuccess(action.type),
      payload: null
    });
  } catch (error) {
    Bugsnag.notify(error);
    return {};
  }
}

function* doReadAssignment(action: AnyAction) {
  try {
    const response = yield repo.fetchAssignment({ id: action.payload });
    yield put({
      type: apiSuccess(action.type),
      payload: response.assignmentEnvelope
    });
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

function* doSaveNewAssignment(action: AnyAction) {
  try {
    const { successCB, ...data } = action.payload;
    const state: GlobalState = yield select();
    const currRequest =
      state.assignment && state.assignment.assignmentResponse
        ? state.assignment.assignmentResponse.pages.curr
        : {};
    yield db.collection("assignments").doc(data.assignment.id).set(data.assignment);
    data.props.map(async (prop) => {
      await db
        .collection("assignments")
        .doc(data.assignment.id)
        .collection("props")
        .doc(prop.id)
        .set(prop);
    });
    if (successCB) successCB();
    yield put(readAllAssignments(currRequest));
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

function* doUpdateAssignmentStatus(action: AnyAction) {
  try {
    const { status, assignmentId } = action.payload;
    const state: GlobalState = yield select();
    const update = {
      assignmentStatus: status,
      updatedAt: serverTimestamp(),
      updatedBy: state.user.me.id,
      ...(status === "closed" && { closedAt: serverTimestamp() }),
      ...(status === "cancelled" && { cancelledAt: serverTimestamp() }),
      ...(status === "reopened" && { reopenedAt: serverTimestamp() })
    };
    yield db.collection("assignments").doc(assignmentId).update(update);
    yield put(readAssignment(assignmentId));
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

function* doUpdateAssignmentsStatus(action: AnyAction) {
  try {
    const { selectedIds, successCB, status } = action.payload;
    const state: GlobalState = yield select();
    const currRequest =
      state.assignment && state.assignment.assignmentResponse
        ? state.assignment.assignmentResponse.pages.curr
        : {};
    selectedIds.map(async (id: string) => {
      const update = {
        assignmentStatus: status,
        updatedAt: serverTimestamp(),
        updatedBy: state.user.me.id,
        ...(status === "closed" && { closedAt: serverTimestamp() }),
        ...(status === "cancelled" && { cancelledAt: serverTimestamp() }),
        ...(status === "reopened" && { reopenedAt: serverTimestamp() })
      };
      await db.collection("assignments").doc(id).update(update);
    });
    if (successCB) successCB();
    yield put(readAllAssignments(currRequest));
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

function* doUpdateTaskStatus(action: AnyAction) {
  try {
    const { id, status, successCB } = action.payload;
    const state: GlobalState = yield select();

    yield db
      .collection("tasks")
      .doc(id)
      .update({
        taskStatus: status,
        updatedAt: serverTimestamp(),
        updatedBy: state.user.me.id,
        ...(status === "cancelled" && { cancelledAt: serverTimestamp() }),
        ...(status === "completed" && { completedAt: serverTimestamp() })
      });
    if (successCB) successCB();
    yield put({
      type: apiSuccess(action.type),
      payload: {
        id,
        status
      }
    });
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

function* doUpdateAssignmentProp(action: AnyAction) {
  try {
    const { successCB, ...data } = action.payload;
    const state: GlobalState = yield select();

    yield db
      .collection("assignments")
      .doc(data.assignmentId)
      .collection("props")
      .doc(data.prop.id)
      .set({
        ...data.prop,
        updatedAt: serverTimestamp(),
        updatedBy: state.user.me.id
      });

    if (successCB) {
      successCB();
    }
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

function* doAssignTask(action: AnyAction) {
  try {
    const payload = action.payload;
    const state: GlobalState = yield select();

    yield db.collection("tasks").doc(payload.taskId).update({
      assignedToUserId: payload.userId,
      assignedToUserName: payload.userName,
      taskStatus: "assigned",
      updatedAt: serverTimestamp(),
      updatedBy: state.user.me.id
    });
    if (payload.successCB()) {
      payload.successCB();
    }
    yield put({
      type: apiSuccess(action.type),
      payload: action.payload
    });
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

async function getTeamInform(teamId: string) {
  const teamInform = await db.doc(`teams/${teamId}`).get();
  const teamRef = await teamInform.ref.collection("team-members").limit(100).get();
  const team = teamInform.data();
  const teamMembers: any[] = [];
  teamRef.forEach((memListSnapshots) => {
    teamMembers.push(memListSnapshots.data());
  });
  team.teamMembers = teamMembers;
  return team;
}

async function getTeamListForAssignmentBlueprint(assignmentBlueprintId: string) {
  try {
    const assignmentBlueprint = await db
      .doc(`assignment-blueprints/${assignmentBlueprintId}`)
      .get()
      .then((doc) => {
        return doc.data();
      });
    const supervisorTeam = await getTeamInform(assignmentBlueprint.supervisorsTeamId);
    return supervisorTeam;
  } catch (err) {
    Bugsnag.notify(err);
    return {};
  }
}

function* doReadAllTasks(action: AnyAction) {
  try {
    const taskList = yield db
      .collection("tasks")
      .limit(100)
      .get()
      .then((taskDocs) => {
        const list: any[] = [];
        taskDocs.forEach((doc) => {
          list.push(doc.data());
        });
        return list;
      });
    yield put({
      type: apiSuccess(action.type),
      payload: taskList
    });
  } catch (error) {
    yield put({
      type: apiError(action.type),
      payload: error
    });
  }
}

function* doReadSuperVisorsForAssignment(action: AnyAction) {
  try {
    const { assignmentBlueprintId } = action.payload;
    const supervisorTeam = yield getTeamListForAssignmentBlueprint(assignmentBlueprintId);
    yield put({
      type: apiSuccess(action.type),
      payload: supervisorTeam.teamMembers || []
    });
  } catch (err) {
    yield put({
      type: apiError(action.type),
      payload: err
    });
  }
}

function* doAssignSupervisor(action: AnyAction) {
  try {
    const payload = action.payload;
    const state: GlobalState = yield select();
    const currRequest = state.assignment.assignmentResponse.pages.curr;
    payload.assignmentIds.forEach(async (id) => {
      await db.collection("assignments").doc(id).update({
        supervisorId: payload.userId,
        supervisorName: payload.userName,
        updatedAt: serverTimestamp(),
        updatedBy: state.user.me.id
      });
    });
    if (payload.successCB) payload.successCB();
    yield put(readAllAssignments(currRequest));
  } catch (error) {
    Bugsnag.notify(error);
  }
}

const doSeedFullTextSearchIndex = apiCall({
  type: SEED_FULL_TEXT_SEARCH_INDEX,
  method: "post",
  path: () => "algolia/index-assignments"
});

export default function* assignmentSaga() {
  yield takeLatest(READ_ASSIGNMENTS, doRepoGetAllAssignments);
  yield takeLatest(SAVE_NEW_ASSIGNMENT, doSaveNewAssignment);
  yield takeLatest(UPDATE_ASSIGNMENTS_STATUS, doUpdateAssignmentsStatus);
  yield takeLatest(UPDATE_ASSIGNMENT_STATUS, doUpdateAssignmentStatus);
  yield takeLatest(READ_ASSIGNMENT, doReadAssignment);
  yield takeLatest(UPDATE_TASK_STATUS, doUpdateTaskStatus);
  yield takeLatest(UPDATE_ASSIGNMENT_PROP, doUpdateAssignmentProp);
  yield takeLatest(ASSIGN_TASK_TO_USER, doAssignTask);
  yield takeLatest(READ_ALL_TASKS, doReadAllTasks);
  yield takeLatest(READ_ASSINGABLE_SUPERVISORS, doReadSuperVisorsForAssignment);
  yield takeLatest(ASSIGN_SUPERVISOR, doAssignSupervisor);
  yield takeLatest(READ_SUBTASK_LIST, doRepoGetSubtasks);
  yield takeLatest(SEED_FULL_TEXT_SEARCH_INDEX, doSeedFullTextSearchIndex);
  yield takeLatest(SEARCH_ALGOLIA, doSearchAlgolia);
}
