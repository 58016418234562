import { put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";

import { SET_DEFAULT_SEARCH, SET_ALGOLIA_ASSIGNMENT_SEARCH } from "../actions/search";
import { apiSuccess } from "../makeRequest";

function* doSetDefaultSearch(action: AnyAction) {
  // helping track state with session storage since this toggle needs to persist between page navigation
  if (action.payload === "local") {
    const localStorageStatus = window.localStorage.getItem("assignmentSearch");
    if (localStorageStatus === "false" || localStorageStatus === null) {
      yield put({ payload: false, type: apiSuccess(action.type) });
    }
  } else {
    window.localStorage.setItem("assignmentSearch", "true");
    yield put({ payload: true, type: apiSuccess(action.type) });
  }
}

function* doSetAlgoliaAssignmentSearch(action: AnyAction) {
  window.localStorage.setItem("assignmentSearch", "true");
  yield put({ payload: true, type: apiSuccess(action.type) });
}

export default function* searchSaga() {
  yield takeLatest(SET_DEFAULT_SEARCH, doSetDefaultSearch);
  yield takeLatest(SET_ALGOLIA_ASSIGNMENT_SEARCH, doSetAlgoliaAssignmentSearch);
}
