import { handleActions } from "redux-actions";
import { SET_DEFAULT_SEARCH, SET_ALGOLIA_ASSIGNMENT_SEARCH } from "../actions/search";
import { apiSuccess } from "../makeRequest";
import { SearchState } from "../../utils/types";

const initialState: SearchState = {
  algoliaAssignments: true,
  algoliaFieldPros: true
};

export default handleActions<SearchState, any>(
  {
    [apiSuccess(SET_DEFAULT_SEARCH)]: (state: SearchState, { payload }) => {
      return {
        ...state,
        algoliaAssignments: payload
      };
    },
    [apiSuccess(SET_ALGOLIA_ASSIGNMENT_SEARCH)]: (state: SearchState, { payload }) => {
      return {
        ...state,
        algoliaAssignments: payload
      };
    }
  },
  initialState
);
